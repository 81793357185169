<template>
     <div
        class="flex flex-col space-y-6 w-1/2">
        <h1 class="text-3xl font-bold">
            Frontend
        </h1>
        <div class="flex justify-start items-center text-center md:text-left space-x-5">
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'js' ? 'text-white bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700' : 'hover:bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 hover:text-white '"
                @click.prevent="active_section = 'js'">
                Javascript
            </button>
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'vue' ? 'text-white bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700' : 'hover:bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 hover:text-white '"
                @click.prevent="active_section = 'vue'">
                Vue.js
            </button>
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'react' ? 'text-white bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700' : 'hover:bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 hover:text-white '"
                @click.prevent="active_section = 'react'">
                React.js
            </button>
        </div>
        <div class="bg-blue-1000 h-56 rounded-3xl">
            <JsHighlighter 
                :code="js_code"
            />
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import JsHighlighter from '../../../components/Highlighter/JsHighlighter.vue';
export default {
    components:{ JsHighlighter },
    setup()
    {
        const active_section = ref('js');
        const js_code = ref(`
        var channel = pusher.subscribe('my-channel');

        channel.bind('my-event', function(data) {
    
            alert('Received my-event with message: ' + data.message);

        });

`)
        return {
            js_code,
            active_section
        }
    }
}
</script>