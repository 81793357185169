<template>
    <div id="Pricing" class="container p-6 mx-auto flex flex-col justify-center items-center pt-32 space-y-16">
        <div class="flex flex-col justify-center items-center space-y-3">
            <h1 class="text-center text-4xl lg:text-6xl font-bold">
                Pricing
            </h1>
            <p class="text-base text-gray-700 md:text-lg">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            </p>
            <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-2 mx-auto">
        </div>
       <!--  <div class="w-full overflow-x-auto overflow-y-hidden z-10 scrollbar-hidden">
            <table class="table block space-y-10 w-full min-w-full">
                <thead class="border-b">
                    <tr>
                        <th class="px-2 py-4">Plan</th>
                        <th class="px-2 py-4">Messages per day</th>
                        <th class="px-2 py-4">Concurrent connections</th>
                        <th class="px-2 py-4">Price (per month)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b-2 border-white">
                        <td class="px-2 py-4 text-center ">
                            <span class="bg-blue-500 text-white p-1 rounded">
                                Base
                            </span>                            
                        </td>
                        <td class="px-2 py-4 text-center">300k</td>
                        <td class="px-2 py-4 text-center">750</td>
                        <td class="px-2 py-4 text-center bg-blue-100 rounded-xl">FREE</td>
                    </tr>
                    <tr class="border-b-2 border-white">
                        <td class="px-2 py-4 text-center ">
                            <span class="bg-blue-500 text-white p-1 rounded">
                                Startup
                            </span>                            
                        </td>
                        <td class="px-2 py-4 text-center">1.5M</td>
                        <td class="px-2 py-4 text-center">1k</td>
                        <td class="px-2 py-4 text-center bg-blue-100 rounded-xl">€20</td>
                    </tr>
                    <tr class="border-b-2 border-white">
                        <td class="px-2 py-4 text-center ">
                            <span class="bg-blue-500 text-white p-1 rounded">
                                PRO
                            </span>                            
                        </td>
                        <td class="px-2 py-4 text-center">5M</td>
                        <td class="px-2 py-4 text-center">3k</td>
                        <td class="px-2 py-4 text-center bg-blue-100 rounded-xl">€49.90</td>
                    </tr>
                    <tr class="border-b-2 border-white">
                        <td class="px-2 py-4 text-center ">
                            <span class="bg-blue-500 text-white p-1 rounded">
                                Business
                            </span>                            
                        </td>
                        <td class="px-2 py-4 text-center">12M</td>
                        <td class="px-2 py-4 text-center">6k</td>
                        <td class="px-2 py-4 text-center bg-blue-100 rounded-xl">€119</td>
                    </tr>
                </tbody>
                
            </table>
            <hr>
        </div> -->
        <!--  -->
        <div class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div class="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-md lg:grid-cols-2 sm:mx-auto">
                <!-- Card 1 -->
                <div class="flex flex-col justify-between p-5 bg-white border rounded-3xl shadow-sm hover:border-deep-orange-500 hover:shadow-xl duration-300">
                    <div class="mb-6">
                        <div class="flex items-center justify-between pb-6 mb-6 border-b">
                            <div>
                                <p class="text-sm font-bold tracking-wider uppercase">
                                    Basic plan
                                </p>
                                <p class="text-4xl font-extrabold flex items-baseline">
                                    <span>€25</span><span class="text-sm">/month</span>
                                </p>
                            </div>
                            <div class="flex items-center justify-center w-24 h-24 rounded-full bg-deep-orange-50 text-deep-orange-500">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M19.89,9.55A1,1,0,0,0,19,9H14V3a1,1,0,0,0-.69-1,1,1,0,0,0-1.12.36l-8,11a1,1,0,0,0-.08,1A1,1,0,0,0,5,15h5v6a1,1,0,0,0,.69.95A1.12,1.12,0,0,0,11,22a1,1,0,0,0,.81-.41l8-11A1,1,0,0,0,19.89,9.55ZM12,17.92V14a1,1,0,0,0-1-1H7l5-6.92V10a1,1,0,0,0,1,1h4Z"/></svg>
                            </div>
                        </div>
                        <div>
                        <p class="mb-2 font-bold tracking-wide">Features</p>
                        <ul class="space-y-2">
                            <li class="flex items-center">
                            <div class="mr-2">
                                <svg class="w-4 h-4 text-deep-orange-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                </svg>
                            </div>
                            <p class="font-medium text-gray-800">10 deploys per day</p>
                            </li>
                            <li class="flex items-center">
                            <div class="mr-2">
                                <svg class="w-4 h-4 text-deep-orange-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                </svg>
                            </div>
                            <p class="font-medium text-gray-800">10 GB of storage</p>
                            </li>
                            <li class="flex items-center">
                            <div class="mr-2">
                                <svg class="w-4 h-4 text-deep-orange-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                </svg>
                            </div>
                            <p class="font-medium text-gray-800">Unlimited domains</p>
                            </li>
                            <li class="flex items-center">
                            <div class="mr-2">
                                <svg class="w-4 h-4 text-deep-orange-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                </svg>
                            </div>
                            <p class="font-medium text-gray-800">SSL Certificates</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div>
                        <a href="/" class="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition-all bg-gradient-to-r from-deep-orange-300 via-pink-300 to-deep-orange-300 bg-size-200 bg-pos-0 hover:bg-pos-100 duration-500 rounded-full shadow-md focus:outline-none">
                            Start
                        </a>
                        <p class="text-sm text-gray-600">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                        </p>
                    </div>
                </div>
                <!-- Card 2 -->
                <div class="flex flex-col justify-between p-5 bg-white border shadow-sm rounded-3xl hover:border-teal-500 hover:shadow-xl duration-300">
                <div class="mb-6">
                    <div class="flex items-center justify-between pb-6 mb-6 border-b">
                        <div>
                            <p class="text-sm font-bold tracking-wider uppercase">
                                Custom
                            </p>
                            <p class="text-4xl font-extrabold flex items-baseline">
                                <span>From €40</span>
                                <span class="text-xs">/month</span>
                            </p>
                        </div>
                        <div class="flex items-center justify-center w-24 h-24 rounded-full bg-teal-50 text-teal-500">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M22.60107,2.062a1.00088,1.00088,0,0,0-.71289-.71289A11.25224,11.25224,0,0,0,10.46924,4.97217L9.35431,6.296l-2.6048-.62848A2.77733,2.77733,0,0,0,3.36279,7.0249L1.1626,10.9248A.99989.99989,0,0,0,1.82422,12.394l3.07275.65869a13.41952,13.41952,0,0,0-.55517,2.43409,1.00031,1.00031,0,0,0,.28466.83642l3.1001,3.1001a.99941.99941,0,0,0,.707.293c.02881,0,.05762-.00147.08692-.00391a12.16892,12.16892,0,0,0,2.49157-.49l.64368,3.00318a1.0003,1.0003,0,0,0,1.46924.66162l3.90527-2.20264a3.03526,3.03526,0,0,0,1.375-3.30371l-.6687-2.759,1.23706-1.13751A11.20387,11.20387,0,0,0,22.60107,2.062ZM3.57227,10.72314,5.12842,7.96338a.82552.82552,0,0,1,1.06982-.37549l1.71741.4162-.65.77179A13.09523,13.09523,0,0,0,5.67633,11.174Zm12.47021,8.22217L13.32666,20.477l-.4295-2.00464a11.33992,11.33992,0,0,0,2.41339-1.61987l.74353-.68366.40344,1.66462A1.041,1.041,0,0,1,16.04248,18.94531ZM17.65674,11.98l-3.68457,3.38623a9.77348,9.77348,0,0,1-5.17041,2.3042l-2.4043-2.4043a10.932,10.932,0,0,1,2.40088-5.206l1.67834-1.99268a.9635.9635,0,0,0,.07813-.09277L11.98975,6.271a9.27757,9.27757,0,0,1,8.80957-3.12012A9.21808,9.21808,0,0,1,17.65674,11.98Zm-.923-6.16376a1.5,1.5,0,1,0,1.5,1.5A1.49992,1.49992,0,0,0,16.7337,5.81622Z"/></svg>
                        </div>
                    </div>
                    <div>
                    <p class="mb-2 font-bold tracking-wide">Features</p>
                    <ul class="space-y-2">
                        <li class="flex items-center">
                        <div class="mr-2">
                            <svg class="w-4 h-4 text-teal-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                            </svg>
                        </div>
                        <p class="font-medium text-gray-800">Unlimited deploys</p>
                        </li>
                        <li class="flex items-center">
                        <div class="mr-2">
                            <svg class="w-4 h-4 text-teal-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                            </svg>
                        </div>
                        <p class="font-medium text-gray-800">Up to 10 Team Members</p>
                        </li>
                        <li class="flex items-center">
                        <div class="mr-2">
                            <svg class="w-4 h-4 text-teal-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                            </svg>
                        </div>
                        <p class="font-medium text-gray-800">100 GB of starge</p>
                        </li>
                        <li class="flex items-center">
                        <div class="mr-2">
                            <svg class="w-4 h-4 text-teal-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                            </svg>
                        </div>
                        <p class="font-medium text-gray-800">24/7 support</p>
                        </li>
                        <li class="flex items-center">
                        <div class="mr-2">
                            <svg class="w-4 h-4 text-teal-500" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                            </svg>
                        </div>
                        <p class="font-medium text-gray-800">Global CDN</p>
                        </li>
                    </ul>
                    </div>
                </div>
                <div>
                    <a
                    href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white 
                    transition-all bg-gradient-to-r from-teal-300 via-blue-700 to-teal-300 bg-size-200 bg-pos-0 hover:bg-pos-100 duration-500 rounded-full focus:outline-none"
                    >
                    Contact Us
                    </a>
                    <p class="text-sm text-gray-600">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                    </p>
                </div>
                </div>
            </div>
        </div>
<!--  -->
    </div>
    
</template>
<script>

export default 
{
    components:
    {
        //
    }
}
</script>