<template>
  <div id="Webhooks" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="flex flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 items-center justify-center">
        <div class="w-full h-full">
            <button @click.prevent="modal = true" class="my-auto">
                <img class="object-cover w-full  rounded-2xl shadow-lg " src="../../assets/img/webhooks.png" alt="" />
            </button>
        </div>
        <div class="lg:pr-10 w-full">
            <div class="ml-auto flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M10,17.55,8.23,19.27a2.47,2.47,0,0,1-3.5-3.5l4.54-4.55a2.46,2.46,0,0,1,3.39-.09l.12.1a1,1,0,0,0,1.4-1.43A2.75,2.75,0,0,0,14,9.59a4.46,4.46,0,0,0-6.09.22L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33L11.37,19A1,1,0,0,0,10,17.55ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L12.63,5A1,1,0,0,0,14,6.45l1.73-1.72a2.47,2.47,0,0,1,3.5,3.5l-4.54,4.55a2.46,2.46,0,0,1-3.39.09l-.12-.1a1,1,0,0,0-1.4,1.43,2.75,2.75,0,0,0,.23.21,4.47,4.47,0,0,0,6.09-.22l4.55-4.55A4.49,4.49,0,0,0,20.69,3.31Z"/></svg>
            </div>
            <h5 class="mb-4 text-4xl font-extrabold leading-none text-right">
                HTTP Webhooks
            </h5>
            <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-5 ml-auto">
            <p class="mb-6 text-gray-900 text-right">
                Trigger HTTP requests whenever a channel gets occupied. Pair it with Lambda functions and soketi will invoke them for you.
            </p>
        </div>
    </div>
  </div>
  <teleport to="body">
    <div v-if="modal" class="fixed inset-0 bg-black z-50 bg-opacity-70 p-5">
       <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="rounded-lg p-5 flex flex-col space-y-5 w-full h-full">
              <div class="flex items-start justify-between">
                <button 
                    title="Close"
                    @click.prevent="closeModal"
                    class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-6 fill-current text-white">
                            <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                        </svg>
                </button>
              </div>
              <div class="w-full h-full p-5">
                <img class="object-cover h-full rounded-2xl shadow-lg mx-auto" src="../../assets/img/webhooks.png" alt="" />
              </div>
            </div>
        </div>
    </div>
  </teleport>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup()
  {
    const modal = ref(false);

    const closeModal = () => 
    {
      modal.value = false;
    };

    return {
      modal,
      closeModal
    }
  }
}
</script>