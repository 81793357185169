<template>
    <footer class="text-white relative min-h-screen lg:h-screen bg-footer bg-cover bg-no-repeat flex flex-col justify-start">
        <div class="absolute inset-x-0 top-0 my-32 z-20">
            <NewsLetter />
        </div>
        <ParticelComponentFooter class="absolute inset-0 z-10"/>
        <div class="absolute bottom-0 inset-x-0 z-20">
            <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
                    <a class="flex title-font font-medium items-center md:justify-start justify-center text-white">
                        <svg viewBox="0 0 1128 501" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current w-32">
                            <path d="M206.645 257.6C206.645 320.8 256.245 366 330.245 366C401.045 366 452.645 320.8 452.645 257.6C452.645 193.6 398.245 148.8 330.245 148.8C261.045 148.8 206.645 193.6 206.645 257.6ZM281.845 257.6C281.845 229.2 303.045 209.2 329.845 209.2C356.245 209.2 377.445 229.2 377.445 257.6C377.445 285.6 357.445 305.6 329.845 305.6C301.045 305.6 281.845 285.6 281.845 257.6ZM587.098 206.8C608.298 206.8 621.498 216.4 621.498 216.4L645.498 168C645.498 168 622.298 148.8 576.698 148.8C517.098 148.8 463.098 197.6 463.098 258C463.098 318 517.498 366 576.698 366C622.298 366 645.498 347.6 645.498 347.6L621.498 298.4C621.498 298.4 608.298 308 587.098 308C560.298 308 538.298 285.6 538.298 257.2C538.298 229.2 560.298 206.8 587.098 206.8ZM651.583 257.6C651.583 320.8 701.183 366 775.183 366C845.983 366 897.583 320.8 897.583 257.6C897.583 193.6 843.183 148.8 775.183 148.8C705.983 148.8 651.583 193.6 651.583 257.6ZM726.783 257.6C726.783 229.2 747.983 209.2 774.783 209.2C801.183 209.2 822.383 229.2 822.383 257.6C822.383 285.6 802.383 305.6 774.783 305.6C745.983 305.6 726.783 285.6 726.783 257.6ZM922.055 360H992.855V16H922.055V360ZM1032.8 360H1103.6V16H1032.8V360Z"/>
                            <path d="M133.458 207.8C154.658 207.8 167.858 217.4 167.858 217.4L191.858 169C191.858 169 168.658 149.8 123.058 149.8C63.4578 149.8 9.45781 198.6 9.45781 259C9.45781 319 63.8578 367 123.058 367C168.658 367 191.858 348.6 191.858 348.6L167.858 299.4C167.858 299.4 154.658 309 133.458 309C106.658 309 84.6578 286.6 84.6578 258.2C84.6578 230.2 106.658 207.8 133.458 207.8Z"/>
                            <circle cx="137" cy="260" r="28"/>
                        </svg>

                    </a>
                    <p class="mt-2 text-sm text-white">Super poteri per i tuoi dati</p>
                </div>
                <div class="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center">
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="font-medium tracking-widest text-sm mb-3 text-white">
                            CATEGORIES
                        </h2>
                        <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-400 hover:underline ">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Fourth Link</a>
                        </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="font-medium tracking-widest text-sm mb-3 text-white">CATEGORIES</h2>
                        <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-400 hover:underline ">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Fourth Link</a>
                        </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="font-medium tracking-widest text-sm mb-3 text-white">CATEGORIES</h2>
                        <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-400 hover:underline ">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Fourth Link</a>
                        </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="font-medium tracking-widest text-sm mb-3 text-white">CATEGORIES</h2>
                        <nav class="list-none mb-10">
                        <li>
                            <a class="text-gray-400 hover:underline ">First Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Second Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Third Link</a>
                        </li>
                        <li>
                            <a class="text-gray-400 hover:underline ">Fourth Link</a>
                        </li>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="">
                <hr class="container mx-auto border-white">
                <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p class="text-gray-300 text-sm text-center sm:text-left">
                    © {{ new Date().getFullYear() }} Cocoll. Powered By
                    <a href="//omnes.one/omnes" class="text-white hover:underline" target="_blank">Omnes</a> - Tutti i diritti riservati.
                </p>
                <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    <a class="text-gray-300">
                    <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                    </a>
                    <a class="ml-3 text-gray-300">
                    <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                    </a>
                    <a class="ml-3 text-gray-300">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                    </a>
                </span>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import ParticelComponentFooter from '../components/ParticelComponentFooter.vue';
import NewsLetter from '../sections/Welcome/NewsLetter.vue';
export default {
    components:{ ParticelComponentFooter, NewsLetter }
}
</script>