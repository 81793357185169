<template>
    <div id="CodeIntegration" class="mt-32">
        <div class="flex flex-col justify-center items-center space-y-3 pt-32">
            <h1 class="text-center text-4xl font-bold">
               Facile da integrare, ancor più facile da usare!
            </h1>
            <p class="text-base text-gray-700 md:text-lg">

                Puoi integrare Cocoll con diverse tecnologie, sia per inviare che ricevere messaggi
            </p>
            <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-2 mx-auto">
        </div>
        <div id="CodeIntegration" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mx-auto flex flex justify-center items-start md:space-x-14">
        <ReceiveCodeSection />
        <SendCodeSection />
            
        </div>
    </div>
</template>
<script>

import SendCodeSection from './CodeIntegration/SendCodeSection.vue';
import ReceiveCodeSection from './CodeIntegration/ReceiveCodeSection.vue';

export default 
{
    components:
    {
        SendCodeSection,
        ReceiveCodeSection
    }
}
</script>