<template>
    <div class="text-gray-900">
        <Header/>
        <CodeIntegration />
        <About/>
        <Cheaper />
        <BlazingFast />
        <PusherCompatible />
        <InAppManagement />
        <Production />
        <Webhooks />
        <Pricing />
        
        <Footer />
    </div>
</template>
<script>
import Header from '../sections/Welcome/Header.vue';
import CodeIntegration from '../sections/Welcome/CodeIntegration.vue';
import About from '../sections/Welcome/About.vue';
import Cheaper from '../sections/Welcome/Cheaper.vue';
import BlazingFast from '../sections/Welcome/BlazingFast.vue';
import PusherCompatible from '../sections/Welcome/PusherCompatible.vue';
import InAppManagement from '../sections/Welcome/InAppManagement.vue';
import Production from '../sections/Welcome/Production.vue';
import Webhooks from '../sections/Welcome/Webhooks.vue';




import Pricing from '../sections/Welcome/Pricing.vue';




import Footer from '../layouts/Footer.vue';

export default {
    components:
    {
        Header,
        CodeIntegration,
        Footer,
        About,
        Pricing,
        BlazingFast,
        Cheaper,
        PusherCompatible,
        InAppManagement,
        Production,
        Webhooks
    }
}
</script>