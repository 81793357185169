<template>
  <div id="BlazingFast" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="flex flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 items-center justify-center">
        <div class="w-full h-full">
            <button @click.prevent="modal = true" class="my-auto">
            <img class="object-cover w-full  rounded-2xl shadow-lg " src="../../assets/img/fast.png" alt="" />
            </button>
        </div>
        <div class="lg:pr-10 w-full">
            <div class="ml-auto flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M19.89,9.55A1,1,0,0,0,19,9H14V3a1,1,0,0,0-.69-1,1,1,0,0,0-1.12.36l-8,11a1,1,0,0,0-.08,1A1,1,0,0,0,5,15h5v6a1,1,0,0,0,.69.95A1.12,1.12,0,0,0,11,22a1,1,0,0,0,.81-.41l8-11A1,1,0,0,0,19.89,9.55ZM12,17.92V14a1,1,0,0,0-1-1H7l5-6.92V10a1,1,0,0,0,1,1h4Z"/></svg>
            </div>
            <h5 class="mb-4 text-4xl font-extrabold leading-none text-right">
                Blazing fast
            </h5>
            <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-5 ml-auto">
            <p class="mb-6 text-gray-900 text-right">
                Guaranteed core message delivery in less than 100ms. Written on top of C, ported to Node.js. Built with one of the fastest available technologies.
            </p>
        </div>
      
    </div>
  </div>
  <teleport to="body">
    <div v-if="modal" class="fixed inset-0 bg-black z-50 bg-opacity-70 p-5">
       <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="rounded-lg p-5 flex flex-col space-y-5 w-full h-full">
              <div class="flex items-start justify-between">
                <button 
                    title="Close"
                    @click.prevent="closeModal"
                    class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-6 fill-current text-white">
                            <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                        </svg>
                </button>
              </div>
              <div class="w-full h-full p-5">
                <img class="object-cover w-full rounded-2xl shadow-lg mx-auto" src="../../assets/img/fast.png" alt="" />
              </div>
            </div>
        </div>
    </div>
  </teleport>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup()
  {
    const modal = ref(false);

    const closeModal = () => 
    {
      modal.value = false;
    };

    return {
      modal,
      closeModal
    }
  }
}
</script>