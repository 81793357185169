<template>
     <div
        class="flex flex-col space-y-6 w-1/2">
        <h1 class="text-3xl font-bold">
            Backend
        </h1>
        <div class="flex justify-start items-center text-center md:text-left space-x-5">
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'php' ? 'text-white bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300' : 'hover:bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 hover:text-white '"
                @click.prevent="active_section = 'php'">
                PHP
            </button>
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'python' ? 'text-white bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300' : 'hover:bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 hover:text-white '"
                @click.prevent="active_section = 'python'">
                Python
            </button>
            <button 
                class="border rounded-full text-lg duration-300 py-2 w-32"
                :class="active_section === 'java' ? 'text-white bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300' : 'hover:bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 hover:text-white '"
                @click.prevent="active_section = 'java'">
                Java
            </button>
        </div>
        <div class="bg-blue-1000 h-56 rounded-3xl">
            <CodeHighlighter 
                v-if="active_section === 'php'"
                :code="php_code"
            />
            <CodeHighlighter 
                v-if="active_section === 'python'"
                :code="python_code"
            />
            <CodeHighlighter 
                v-if="active_section === 'java'"
                :code="java_code"
            />
        </div>
        
        
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import CodeHighlighter from '../../../components/Highlighter/JsHighlighter.vue';

export default {
    components:{ CodeHighlighter },
    setup()
    {
        const active_section = ref('php');
        const php_code = ref(`
        pusher->trigger('my-channel', 

                        'my-event', 

                        [ 'message' => 'hello world' ]);
 `);
        const python_code = ref(`
        pusher.trigger('my-channel', 
                        
                        'my-event', 
                        
                        { 'message': 'hello world' })
 `);
        const java_code = ref(` 
        pusher.trigger("my-channel", 

                        "my-event", 

                        Collections.singletonMap(
                                
                                "message", "hello world"
                        ));
 `);
        return {
            php_code,
            python_code,
            java_code,
            active_section
        }
    }
}
</script>