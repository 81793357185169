
<template>
 
        <div class="relative min-h-screen lg:h-screen bg-header bg-cover bg-no-repeat z-10">
            <ParticelComponent class="absolute inset-0 z-10"/>
            <!--  -->
            <div class="absolute  inset-0 flex flex-col items-center justify-center px-4 mx-auto sm:max-w-xl md:max-w-full  md:px-0 space-y-5 z-10 pt-72">
                <div class="flex flex-col items-center max-w-2xl md:px-8 space-y-10">
                    <div class="max-w-xl  md:mx-auto sm:text-center lg:max-w-2xl">
                        <h2 class="max-w-lg mb-6 text-3xl leading-none tracking-tight text-white md:mx-auto">
                            Scopri il potere del
                        </h2>
                        <h2 class="max-w-lg mb-6 text-3xl font-extra-bold leading-none tracking-tight text-white sm:text-4xl lg:text-6xl md:mx-auto">
                            <span class="realtime-gradient">Real-Time</span> 
                        </h2>
                        <p class="text-base text-white md:text-lg">
                            Cocoll è il nuovo servizio Omnes pensato per la gestione dati in tempo reale attraverso l'architettura web socket.
                        </p>
                    </div>
                    <div class="flex items-center justify-center">
                        <router-link 
                            to="#CodeIntegration"
                            class="transition-all bg-gradient-to-r from-teal-300 via-blue-700 to-teal-300 bg-size-200 bg-pos-0 hover:bg-pos-100 text-lg rounded-full px-6 py-3 text-white duration-500">
                            Inizia ora
                        </router-link>
                    </div>
                </div>
                <div class="container mx-auto z-20 w-full max-w-3xl  rounded-xl bg-browser bg-no-repeat bg-cover pt-12 pb-4 px-4">
                    <video loop autoplay="autoplay" class="w-full  mx-auto rounded shadow-2xl md:w-auto">
                            <source src="/video/Intro-Cocoll.mov">
                            Your browser does not support HTML video.
                    </video>
                </div>
                
                <!-- <video loop autoplay="autoplay" class="w-full max-w-screen-sm mx-auto rounded shadow-2xl md:w-auto lg:max-w-screen-md">
                        <source src="/video/Intro-Cocoll.mov">
                        Your browser does not support HTML video.
                </video> -->
            </div>
        </div>
 
</template>
<script>
import ParticelComponent from '../../components/ParticelComponent.vue';
import 'particles.js'
export default {
 components:{ ParticelComponent }
};
</script>

<style scoped>
.realtime-gradient
{
  /* background: -webkit-linear-gradient(#ff8a65, #ff8a65); */
  background-image: linear-gradient(90deg, #4db6ac, #4db6ac, #1976d2);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
</style>