<template>
  <div id="InAppManagement" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="flex flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 items-center justify-center">
        <div class="w-full h-full">
            <button @click.prevent="modal = true" class="my-auto">
            <img class="object-cover w-full  rounded-2xl shadow-lg " src="../../assets/img/inAppManagement.png" alt="" />
            </button>
        </div>
        <div class="lg:pr-10 w-full">
            <div class="ml-auto flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17" class="w-8 h-8 fill-current"><g></g><path d="M9.5 10.972c0 0.366-0.207 0.672-0.5 0.846v2.154h-1v-2.153c-0.294-0.174-0.5-0.48-0.5-0.847 0-0.552 0.447-1 1-1 0.551 0 1 0.447 1 1zM14.965 8.46v7.080c0 0.827-0.673 1.5-1.5 1.5h-9.989c-0.827 0-1.5-0.673-1.5-1.5v-7.080c0-0.827 0.673-1.5 1.5-1.5h0.024v-1.988c0-2.757 2.243-5 5-5s5 2.243 5 5v1.992c0.811 0.018 1.465 0.681 1.465 1.496zM4.5 6.96h8v-1.988c0-2.206-1.794-4-4-4s-4 1.794-4 4v1.988zM13.965 8.46c0-0.264-0.207-0.474-0.465-0.493v0.004h-10v-0.011h-0.024c-0.275 0-0.5 0.224-0.5 0.5v7.080c0 0.276 0.225 0.5 0.5 0.5h9.989c0.275 0 0.5-0.224 0.5-0.5v-7.080z"/></svg>
            </div>
            <h5 class="mb-4 text-4xl font-extrabold leading-none text-right">
                Built-in app management
            </h5>
            <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-5 ml-auto">
            <p class="mb-6 text-gray-900 text-right">
                You can scale to hundreds of servers with Redis or NATS and you won't lose performance. It is resilient enough so you can have a good night sleep.
            </p>
        </div>
    </div>
  </div>
  <teleport to="body">
    <div v-if="modal" class="fixed inset-0 bg-black z-50 bg-opacity-70 p-5">
       <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="rounded-lg p-5 flex flex-col space-y-5 w-full h-full">
              <div class="flex items-start justify-between">
                <button 
                    title="Close"
                    @click.prevent="closeModal"
                    class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-6 fill-current text-white">
                            <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                        </svg>
                </button>
              </div>
              <div class="w-full h-full p-5">
                <img class="object-cover w-full rounded-2xl shadow-lg mx-auto" src="../../assets/img/inAppManagement.png" alt="" />
              </div>
            </div>
        </div>
    </div>
  </teleport>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup()
  {
    const modal = ref(false);

    const closeModal = () => 
    {
      modal.value = false;
    };

    return {
      modal,
      closeModal
    }
  }
}
</script>