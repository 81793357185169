<template>
  <div id="Production" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="flex flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-10 items-center justify-center">
      <div class="lg:pr-10 w-full">
        <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-8 h-8 fill-current"><path d="M22.60107,2.062a1.00088,1.00088,0,0,0-.71289-.71289A11.25224,11.25224,0,0,0,10.46924,4.97217L9.35431,6.296l-2.6048-.62848A2.77733,2.77733,0,0,0,3.36279,7.0249L1.1626,10.9248A.99989.99989,0,0,0,1.82422,12.394l3.07275.65869a13.41952,13.41952,0,0,0-.55517,2.43409,1.00031,1.00031,0,0,0,.28466.83642l3.1001,3.1001a.99941.99941,0,0,0,.707.293c.02881,0,.05762-.00147.08692-.00391a12.16892,12.16892,0,0,0,2.49157-.49l.64368,3.00318a1.0003,1.0003,0,0,0,1.46924.66162l3.90527-2.20264a3.03526,3.03526,0,0,0,1.375-3.30371l-.6687-2.759,1.23706-1.13751A11.20387,11.20387,0,0,0,22.60107,2.062ZM3.57227,10.72314,5.12842,7.96338a.82552.82552,0,0,1,1.06982-.37549l1.71741.4162-.65.77179A13.09523,13.09523,0,0,0,5.67633,11.174Zm12.47021,8.22217L13.32666,20.477l-.4295-2.00464a11.33992,11.33992,0,0,0,2.41339-1.61987l.74353-.68366.40344,1.66462A1.041,1.041,0,0,1,16.04248,18.94531ZM17.65674,11.98l-3.68457,3.38623a9.77348,9.77348,0,0,1-5.17041,2.3042l-2.4043-2.4043a10.932,10.932,0,0,1,2.40088-5.206l1.67834-1.99268a.9635.9635,0,0,0,.07813-.09277L11.98975,6.271a9.27757,9.27757,0,0,1,8.80957-3.12012A9.21808,9.21808,0,0,1,17.65674,11.98Zm-.923-6.16376a1.5,1.5,0,1,0,1.5,1.5A1.49992,1.49992,0,0,0,16.7337,5.81622Z"/></svg>
        </div>
        <h5 class="mb-4 text-4xl font-extrabold leading-none">
          Ready for production
        </h5>
        <hr class="border-transparent h-1 w-32 bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-5">
        <p class="mb-6 text-gray-900">
            You don't need to replace your existing code. It's using the Pusher Protocol v7. This means you just connect to the server.
        </p>
      </div>
      <div class="w-full">
        <button @click.prevent="modal = true">
          <img class="object-cover w-full rounded-2xl shadow-lg bg-teal-500" src="../../assets/img/production.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div v-if="modal" class="fixed inset-0 bg-black z-50 bg-opacity-70 p-5">
       <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="rounded-lg p-5 flex flex-col space-y-5 w-full h-full">
              <div class="flex items-start justify-between">
                <button 
                    title="Close"
                    @click.prevent="closeModal"
                    class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-6 fill-current text-white">
                            <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                        </svg>
                </button>
              </div>
              <div class="w-full h-full p-5">
                <img class="object-cover h-full rounded-2xl shadow-lg mx-auto bg-teal-500" src="../../assets/img/production.svg" alt="" />
              </div>
            </div>
        </div>
    </div>
  </teleport>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup()
  {
    const modal = ref(false);

    const closeModal = () => 
    {
      modal.value = false;
    };

    return {
      modal,
      closeModal
    }
  }
}
</script>