<template>
    <div class="px-4 py-16 bg-white border rounded-3xl shadow mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 flex items-start justify-between space-x-8">
        <div class="w-full">
            <h2 class="max-w-lg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 text-4xl">
                Subscribe Our Newsletter
            </h2>
            <p class="text-base text-gray-700">
                And don't miss any updates!
            </p>
        </div>
        <div class="w-full h-full">
            <div class="flex items-start">
                <input type="text" placeholder="Email" class="text-black rounded-l-full border px-4 h-14 w-full hover:border-deep-orange-300 focus:border-deep-orange-300">
                <button
                    class="rounded-r-full px-4 h-14 transition-all duration-500
                     bg-gradient-to-t from-deep-orange-300 via-pink-300 to-deep-orange-300 bg-size-200 bg-pos-0 hover:bg-pos-100 "
                >
                    Subscribe
                </button>
            </div>
        </div>
    </div>
</template>