<template>
    <div id="Feature" class="min-h-screen bg-white flex flex-col space-y-10">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <hr class="border-transparent h-1 w-32 mx-auto bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 mb-5">
                <h2 class="max-w-lg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    Economico, flessibile ed incredibilmente veloce.
                </h2>
                <p class="text-base text-gray-700 md:text-lg">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium

                </p>
            </div>
            <div class="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
                <!-- Card 1 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M14,11H13V7h2a1,1,0,0,1,1,1,1,1,0,0,0,2,0,3,3,0,0,0-3-3H13V3a1,1,0,0,0-2,0V5H10a4,4,0,0,0,0,8h1v4H9a1,1,0,0,1-1-1,1,1,0,0,0-2,0,3,3,0,0,0,3,3h2v2a1,1,0,0,0,2,0V19h1a4,4,0,0,0,0-8Zm-3,0H10a2,2,0,0,1,0-4h1Zm3,6H13V13h1a2,2,0,0,1,0,4Z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">Super economico</h6>
                    <p class="text-sm text-gray-900">
                        This is because you pay only for your infrastructure. Whether it's your basement server or cloud, it fits and runs. It can be as cheap as $5 on DigitalOcean
                    </p>
                    <a href="#Cheaper" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
                <!-- Card 2 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M19.89,9.55A1,1,0,0,0,19,9H14V3a1,1,0,0,0-.69-1,1,1,0,0,0-1.12.36l-8,11a1,1,0,0,0-.08,1A1,1,0,0,0,5,15h5v6a1,1,0,0,0,.69.95A1.12,1.12,0,0,0,11,22a1,1,0,0,0,.81-.41l8-11A1,1,0,0,0,19.89,9.55ZM12,17.92V14a1,1,0,0,0-1-1H7l5-6.92V10a1,1,0,0,0,1,1h4Z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">Veloce, veloce, veloce.</h6>
                    <p class="text-sm text-gray-900">
                        Guaranteed core message delivery in less than 100ms. Written on top of C, ported to Node.js. Built with one of the fastest available technologies.
                    </p>
                    <a href="#BlazingFast" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
                <!-- Card 3 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-8 h-8 fill-current"><path d="M22.60107,2.062a1.00088,1.00088,0,0,0-.71289-.71289A11.25224,11.25224,0,0,0,10.46924,4.97217L9.35431,6.296l-2.6048-.62848A2.77733,2.77733,0,0,0,3.36279,7.0249L1.1626,10.9248A.99989.99989,0,0,0,1.82422,12.394l3.07275.65869a13.41952,13.41952,0,0,0-.55517,2.43409,1.00031,1.00031,0,0,0,.28466.83642l3.1001,3.1001a.99941.99941,0,0,0,.707.293c.02881,0,.05762-.00147.08692-.00391a12.16892,12.16892,0,0,0,2.49157-.49l.64368,3.00318a1.0003,1.0003,0,0,0,1.46924.66162l3.90527-2.20264a3.03526,3.03526,0,0,0,1.375-3.30371l-.6687-2.759,1.23706-1.13751A11.20387,11.20387,0,0,0,22.60107,2.062ZM3.57227,10.72314,5.12842,7.96338a.82552.82552,0,0,1,1.06982-.37549l1.71741.4162-.65.77179A13.09523,13.09523,0,0,0,5.67633,11.174Zm12.47021,8.22217L13.32666,20.477l-.4295-2.00464a11.33992,11.33992,0,0,0,2.41339-1.61987l.74353-.68366.40344,1.66462A1.041,1.041,0,0,1,16.04248,18.94531ZM17.65674,11.98l-3.68457,3.38623a9.77348,9.77348,0,0,1-5.17041,2.3042l-2.4043-2.4043a10.932,10.932,0,0,1,2.40088-5.206l1.67834-1.99268a.9635.9635,0,0,0,.07813-.09277L11.98975,6.271a9.27757,9.27757,0,0,1,8.80957-3.12012A9.21808,9.21808,0,0,1,17.65674,11.98Zm-.923-6.16376a1.5,1.5,0,1,0,1.5,1.5A1.49992,1.49992,0,0,0,16.7337,5.81622Z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">Compatibile con Pusher</h6>
                    <p class="text-sm text-gray-900">
                        You don't need to replace your existing code. It's using the Pusher Protocol v7. This means you just connect to the server.
                    </p>
                    <a href="#Pusher" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
                <!-- Card 4 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17" class="w-8 h-8 fill-current"><g></g><path d="M9.5 10.972c0 0.366-0.207 0.672-0.5 0.846v2.154h-1v-2.153c-0.294-0.174-0.5-0.48-0.5-0.847 0-0.552 0.447-1 1-1 0.551 0 1 0.447 1 1zM14.965 8.46v7.080c0 0.827-0.673 1.5-1.5 1.5h-9.989c-0.827 0-1.5-0.673-1.5-1.5v-7.080c0-0.827 0.673-1.5 1.5-1.5h0.024v-1.988c0-2.757 2.243-5 5-5s5 2.243 5 5v1.992c0.811 0.018 1.465 0.681 1.465 1.496zM4.5 6.96h8v-1.988c0-2.206-1.794-4-4-4s-4 1.794-4 4v1.988zM13.965 8.46c0-0.264-0.207-0.474-0.465-0.493v0.004h-10v-0.011h-0.024c-0.275 0-0.5 0.224-0.5 0.5v7.080c0 0.276 0.225 0.5 0.5 0.5h9.989c0.275 0 0.5-0.224 0.5-0.5v-7.080z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">Built-in app management</h6>
                    <p class="text-sm text-gray-900">
                        Just like Pusher, you can define your own app credentials. You don't even need Pusher for it, and you can read from any database. 
                    </p>
                    <a href="#InAppManagement" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
                <!-- Card 3 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-deep-orange-300 via-deep-orange-300 to-pink-300 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M21.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,21,6H16a1,1,0,0,0,0,2h2.59L13,13.59l-3.29-3.3a1,1,0,0,0-1.42,0l-6,6a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L9,12.41l3.29,3.3a1,1,0,0,0,1.42,0L20,9.41V12a1,1,0,0,0,2,0V7A1,1,0,0,0,21.92,6.62Z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">Pronto per la distribuzione</h6>
                    <p class="text-sm text-gray-900">
                        You can scale to hundreds of servers with Redis or NATS and you won't lose performance. It is resilient enough so you can have a good night sleep.
                    </p>
                    <a href="#Production" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
                <!-- Card 6 -->
                <div class="p-5 duration-300 transform bg-white border rounded-3xl shadow-sm hover:shadow-xl">
                    <div class="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-gradient-to-r from-teal-300 via-teal-300 to-blue-700 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10 fill-current"><path d="M10,17.55,8.23,19.27a2.47,2.47,0,0,1-3.5-3.5l4.54-4.55a2.46,2.46,0,0,1,3.39-.09l.12.1a1,1,0,0,0,1.4-1.43A2.75,2.75,0,0,0,14,9.59a4.46,4.46,0,0,0-6.09.22L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33L11.37,19A1,1,0,0,0,10,17.55ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L12.63,5A1,1,0,0,0,14,6.45l1.73-1.72a2.47,2.47,0,0,1,3.5,3.5l-4.54,4.55a2.46,2.46,0,0,1-3.39.09l-.12-.1a1,1,0,0,0-1.4,1.43,2.75,2.75,0,0,0,.23.21,4.47,4.47,0,0,0,6.09-.22l4.55-4.55A4.49,4.49,0,0,0,20.69,3.31Z"/></svg>
                    </div>
                    <h6 class="mb-2 font-semibold leading-5">
                        HTTP Webhooks
                    </h6>
                    <p class="text-sm text-gray-900">
                        Trigger HTTP requests whenever a channel gets occupied. Pair it with Lambda functions and soketi will invoke them for you.
                    </p>
                    <a href="#Webhooks" class="hover:underline flex items-center justify-center space-x-1 max-w-max mt-3 ml-auto">
                        <span>Scopri di più </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.illustration {
  transform: scaleX(-1);
}
</style>